var listName = ['instructionArm', 'instructionPayer', 'apiInterface', 'arm'];
var linksName = ['instructionArm', 'instructionPayer', 'apiInterface', 'arm'];

var link = '//udfl.nalog.gov.by/download/';

if (!Array.prototype.indexOf) {
  Array.prototype.indexOf = function (searchElement, fromIndex) {
    var k;
    if (this == null) {
      throw new TypeError('"this" is null or not defined');
    }
    var o = Object(this);
    var len = o.length >>> 0;
    if (len === 0) {
      return -1;
    }
    var n = +fromIndex || 0;
    if (Math.abs(n) === Infinity) {
      n = 0;
    }
    if (n >= len) {
      return -1;
    }
    k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);
    while (k < len) {
      if (k in o && o[k] === searchElement) {
        return k;
      }
      k++;
    }
    return -1;
  };
}

if (typeof String.prototype.trim !== 'function') {
  String.prototype.trim = function () {
    return this.replace(/^\s+|\s+$/g, '');
  }
}

getFolder(buildObjectVersion);

function buildObjectVersion(html) {
  var el = document.createElement('div');
  el.innerHTML = html;
  var list = el.getElementsByTagName('a');
  for (var i = 1; i < list.length; i++) {
    if (list[i].innerText.split('-').length > 1) {
      var str = list[i].innerText.split('.');
      var parts = str[0].split('-');
      if (linksName.indexOf(parts[0].replace(/\s+/g, '')) >= 0) {
        var name = parts[0].replace(/\s+/g, '');
        var version = parts[1].replace(/_/g, '.');
        var htmlElement = document.getElementById(name);
        var htmlElementLink = document.getElementById(name + 'Link');
        var strLink = str.join('.').trim();
        var location = window.location.href;
        var arr = location.split("/");
        var result = arr[0];
        htmlElementLink.href = result + link + strLink;
        htmlElement.innerHTML = version;
      }
    }
  }
}

function getFolder(callback) {
  var xhr = new XMLHttpRequest();
  var location = window.location.href;
  var arr = location.split("/");
  var result = arr[0];
  var url = result + '//udfl.nalog.gov.by/download';
  xhr.open('GET', url, true);
  xhr.send();
  xhr.onreadystatechange = function () {
    if (xhr.readyState != 4) return;
    if (xhr.status != 200) {
      //console.log(xhr.status + ': ' + xhr.statusText);
    } else {
      callback(xhr.responseText);
    }
  };
}

function scrolll(e) {
  var startX = 0,
    endX = 0,
    startY = 0,
    endY = document.getElementById(e).offsetTop,
    distanceX = endX - startX,
    distanceY = endY - startY,
    startTime = new Date().getTime(),
    duration = 400;
  var easeInOutQuart = function (time, from, distance, duration) {
    if ((time /= duration / 2) < 1)
      return (distance / 2) * time * time * time * time + from;
    return (-distance / 2) * ((time -= 2) * time * time * time - 2) + from;
  };
  var timer = window.setInterval(function () {
    var time = new Date().getTime() - startTime,
      newX = easeInOutQuart(time, startX, distanceX, duration),
      newY = easeInOutQuart(time, startY, distanceY, duration);
    if (time >= duration) {
      window.clearInterval(timer);
    }
    window.scrollTo(newX, newY);
  }, 1000 / 60);
}

function onClickInstruction(id) {
  var button = document.getElementById(id);
  button.click();
}

// Добавляем прослушку на скролл
if (document.addEventListener) { //проверка для ie8   
  document.addEventListener("scroll", scrollWin);
} else {
  document.attachEvent("onscroll", scrollWin);
}

function scrollWin(e) {
  var scrolled = window.pageYOffset || document.documentElement.scrollTop;
  var arrow = document.getElementById('toTop');
  var windowHeight = document.documentElement.clientHeight;  
  if (scrolled > windowHeight/2) {
    arrow.className = "arrow-top cursor-pointer arrow-top-visible";
  } else {
    arrow.className = "arrow-top cursor-pointer";
  }

}
function showBlock(event) {
  var source = event.target || event.srcElement;
  if (source.parentNode.className == 'panel') {
      source.parentNode.className = 'panel collapsed';
  } else {
      source.parentNode.className = 'panel';
  }
}

function openModal(num) {
  var modal = document.getElementById('modal' + num);
  modal.className = 'modal active';
  document.body.style.overflowY = 'hidden';
}

function closeModal(num) {
  var modal = document.getElementById('modal' + num);
  modal.className = 'modal';
  document.body.style.overflowY = 'auto';
}